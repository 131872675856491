import humps from 'humps'

import { defineAction } from '_utils/redux'
import * as agencyService from '_services/agency'
import { BANK_ACCOUNT_FIELDS } from '_utils/constants'
import { notEmptyPayload, parseNumber, formatDateDays } from '_utils/helpers'
import { AGENCY_FIELDS } from '_views/agency/constants'

export const GET_AGENCIES = defineAction('GET_AGENCIES')
export const GET_NEXT_PAGE_AGENCIES = defineAction('GET_NEXT_PAGE_AGENCIES')
export const GET_AGENCIES_FILTERED = defineAction('GET_AGENCIES_FILTERED')
export const GET_AGENCY = defineAction('GET_AGENCY')
export const GET_AGENCY_FULL = defineAction('GET_AGENCY_FULL')
export const UPDATE_AGENCY = defineAction('UPDATE_AGENCY')
export const CREATE_AGENCY = defineAction('CREATE_AGENCY')
export const ADD_AGENCY_STAFF_USER = defineAction('ADD_AGENCY_STAFF_USER')
export const DELETE_AGENCY_STAFF_USER = defineAction('DELETE_AGENCY_STAFF_USER')
export const UPDATE_AGENCY_STAFF_USER = defineAction('UPDATE_AGENCY_STAFF_USER')
export const GET_AGENCY_RESPONSIBLE = defineAction('GET_AGENCY_RESPONSIBLE')
export const GET_AGENCY_DETAILS = defineAction('GET_AGENCY_DETAILS')
export const HANDLE_SET_PAGE = 'HANDLE_SET_PAGE'
export const HANDLE_SET_FILTER = 'HANDLE_SET_FILTER'
export const DELETE_AGENCY_CONTRACT = defineAction('DELETE_AGENCY_CONTRACT')
export const GET_OPTIONS_FIELD_NAME = defineAction('GET_OPTIONS_FIELD_NAME')
export const GET_SECTOR_AGENCY_BY_USER_ID = defineAction('GET_SECTOR_AGENCY_BY_USER_ID')
export const GET_AGENCIES_SIMPLE = defineAction('GET_AGENCIES_SIMPLE')
export const GET_ADDITIONAL_TAKE_RATES_BY_AGENCY = defineAction(
  'GET_ADDITIONAL_TAKE_RATES_BY_AGENCY'
)

export const PATCH_AGENCY_USER = defineAction('PATCH_AGENCY_USER')
export const POST_AGENCY_USER = defineAction('POST_AGENCY_USER')

const formatPayload = (values, clearEmptyFields = true) => {
  const formattedPayload = clearEmptyFields ? notEmptyPayload(values) : values

  const payload = Object.entries(formattedPayload).reduce((acc, [key, value]) => {
    if (Object.values(BANK_ACCOUNT_FIELDS).includes(key)) {
      if (key === BANK_ACCOUNT_FIELDS.BANK_ACCOUNT_CPF_CNPJ && value) {
        return { ...acc, bankAccount: { ...acc.bankAccount, [key]: parseNumber(value) } }
      }
      if (key === BANK_ACCOUNT_FIELDS.BANK) {
        return { ...acc, bankAccount: { ...acc.bankAccount, [key]: Number(value) } }
      }
      return { ...acc, bankAccount: { ...acc.bankAccount, [key]: value } }
    }

    return { ...acc, [key]: value }
  }, {})

  payload.bankAccount = humps.decamelizeKeys(payload.bankAccount)

  if (typeof payload[AGENCY_FIELDS.BRAND_LOGO_PNG_URL] === 'string') {
    delete payload[AGENCY_FIELDS.BRAND_LOGO_PNG_URL]
  }

  if (payload[AGENCY_FIELDS.IS_ACTIVE]) {
    payload[AGENCY_FIELDS.IS_ACTIVE] = payload[AGENCY_FIELDS.IS_ACTIVE] === 'true'
  }

  if (payload[AGENCY_FIELDS.PHONE_NUMBER]) {
    payload[AGENCY_FIELDS.PHONE_NUMBER] = parseNumber(payload[AGENCY_FIELDS.PHONE_NUMBER])
  }

  if (payload.phone) {
    payload.phone = parseNumber(payload.phone)
  }

  if (payload[AGENCY_FIELDS.CPF_CNPJ]) {
    payload[AGENCY_FIELDS.CPF_CNPJ] = parseNumber(payload[AGENCY_FIELDS.CPF_CNPJ])
  }

  if (payload[AGENCY_FIELDS.AGENCY_CREATION_DATE]) {
    payload[AGENCY_FIELDS.AGENCY_CREATION_DATE] = formatDateDays(
      payload[AGENCY_FIELDS.AGENCY_CREATION_DATE],
      'dd/MM/yyyy',
      'yyyy-MM-dd'
    )
  }

  if (payload[AGENCY_FIELDS.AGENCY_COMPLETION_PERCENTAGE]) {
    payload[AGENCY_FIELDS.AGENCY_COMPLETION_PERCENTAGE] = payload[
      AGENCY_FIELDS.AGENCY_COMPLETION_PERCENTAGE
    ]
      ?.toString()
      ?.replace(',', '.')
  }
  if (payload[AGENCY_FIELDS.REFERA_COMPLETION_PERCENTAGE]) {
    payload[AGENCY_FIELDS.REFERA_COMPLETION_PERCENTAGE] = payload[
      AGENCY_FIELDS.REFERA_COMPLETION_PERCENTAGE
    ]
      ?.toString()
      ?.replace(',', '.')
  }
  if (payload[AGENCY_FIELDS.MINIMUM_SALE_VALUE]) {
    payload[AGENCY_FIELDS.MINIMUM_SALE_VALUE] = payload[AGENCY_FIELDS.MINIMUM_SALE_VALUE]
      ?.toString()
      ?.replace(',', '.')
  }
  return payload
}

export const getAgencies = params => async (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || 1000,
  }
  const data = await agencyService.getAgencies(getState().user.authToken)(queryParams)
  dispatch({
    type: GET_AGENCIES.ACTION,
    payload: async () => data,
    meta: queryParams,
  })
  return data
}

export const getAgenciesSimple = () => (dispatch, getState) => {
  const data = agencyService.getAgenciesSimple(getState().user.authToken)()
  dispatch({
    type: GET_AGENCIES_SIMPLE.ACTION,
    payload: data,
  })
  return data
}

export const getAgenciesNextPage = params => async (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().agencies.size,
    page: getState().agencies.next,
    ...getState().agencies.filter.toJS(),
  }
  const data = await agencyService.getAgencies(getState().user.authToken)(queryParams)
  dispatch({
    type: GET_NEXT_PAGE_AGENCIES.ACTION,
    payload: async () => data,
  })
  return data
}

export const getAgenciesFiltered =
  (filter = {}) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_AGENCIES_FILTERED.ACTION,
      payload: agencyService.getAgencies(getState().user.authToken)({
        ...getState().agencies.filter.toJS(),
        ...filter,
      }),
    })
  }

export const setTablePage = page => dispatch =>
  dispatch({
    type: HANDLE_SET_PAGE,
    payload: page,
  })

export const setFilter = param => dispatch =>
  dispatch({
    type: HANDLE_SET_FILTER,
    payload: param,
  })

export const getAgency = agencyId => (dispatch, getState) => {
  const data = agencyService.getAgency(getState().user.authToken)(agencyId)
  dispatch({
    type: GET_AGENCY.ACTION,
    payload: data,
  })
  return data
}

export const getAgencyFull = agencyId => async (dispatch, getState) => {
  const data = await agencyService.getAgencyFull(getState().user.authToken)(agencyId)
  dispatch({
    type: GET_AGENCY_FULL.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateAgency =
  (value, clearEmptyFields = true) =>
  async (dispatch, getState) => {
    const agencyId = getState().agencies.currentAgency
    // eslint-disable-next-line no-unused-vars
    const { staffUsers, ...payload } = value
    const data = await agencyService.updateAgency(getState().user.authToken)(
      agencyId,
      formatPayload(payload, clearEmptyFields)
    )

    dispatch({
      type: UPDATE_AGENCY.ACTION,
      payload: async () => data,
    })

    return data
  }
export const createAgency = payload => async (dispatch, getState) => {
  const data = await agencyService.createAgency(getState().user.authToken)(formatPayload(payload))
  dispatch({
    type: CREATE_AGENCY.ACTION,
    payload: async () => data,
  })
  return data
}

export const addAgencyStaffUser = payload => async (dispatch, getState) => {
  const agencyId = getState().agencies.currentAgency
  const data = await agencyService.addAgencyStaffUser(getState().user.authToken)(agencyId, payload)
  dispatch({
    type: ADD_AGENCY_STAFF_USER.ACTION,
    payload: async () => data,
    meta: { agencyId },
  })
}

export const patchAgencyStaffUser = (userId, payload) => async (dispatch, getState) => {
  const agencyId = getState().agencies.currentAgency
  const data = await agencyService.updateAgencyStaffUser(getState().user.authToken)(
    agencyId,
    userId,
    payload
  )
  dispatch({
    type: UPDATE_AGENCY_STAFF_USER.ACTION,
    payload: async () => data,
    meta: { agencyId },
  })
}

export const deleteAgencyStaffUser = staffId => (dispatch, getState) => {
  const agencyId = getState().agencies.currentAgency
  dispatch({
    type: DELETE_AGENCY_STAFF_USER.ACTION,
    payload: agencyService.deleteAgencyStaffUser(getState().user.authToken)(agencyId, staffId),
    meta: { agencyId, staffId },
  })
}

export const deleteAgencyContract = attachmentId => (dispatch, getState) => {
  const agencyId = getState().agencies.currentAgency

  dispatch({
    type: DELETE_AGENCY_CONTRACT.ACTION,
    payload: agencyService.deleteAgencyContract(getState().user.authToken)(agencyId, attachmentId),
    meta: { attachmentId, agencyId },
  })
}

export const updateAgencyStaffUser = values => (dispatch, getState) => {
  const agencyId = getState().agencies.currentAgency
  const currentStaffUser = getState().agencies.results.getIn([agencyId, 'staffUsers', values.id])
  const { id, ...payload } = values
  if (currentStaffUser?.email === payload.email) {
    delete payload.email
  }
  dispatch({
    type: UPDATE_AGENCY_STAFF_USER.ACTION,
    payload: agencyService.updateAgencyStaffUser(getState().user.authToken)(
      agencyId,
      id,
      formatPayload(payload)
    ),
    meta: { agencyId },
  })
}

export const getAgencyResponsible =
  (agencyId, responsibleId = null) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_AGENCY_RESPONSIBLE.ACTION,
      payload: agencyService.getServiceOrderResponsible(getState().user.authToken)(
        agencyId,
        responsibleId
      ),
    })
  }

export const getAgencyDetails = agencyId => (dispatch, getState) => {
  const data = agencyService.getAgencyDetails(getState().user.authToken)(agencyId)
  dispatch({
    type: GET_AGENCY_DETAILS.ACTION,
    payload: data,
  })
  return data
}

export const getOptionsFieldName = agencyId => (dispatch, getState) => {
  dispatch({
    type: GET_OPTIONS_FIELD_NAME.ACTION,
    payload: agencyService.getOptionsFieldName(getState().user.authToken)(agencyId),
  })
}

export const getSectorsAgencyByUserId = userAddId => (dispatch, getState) => {
  const data = agencyService.getSectorsAgencyByUserId(getState().user.authToken)(userAddId)
  dispatch({
    type: GET_SECTOR_AGENCY_BY_USER_ID.ACTION,
    payload: data,
  })
  return data
}

export const getAdditionalTakeRatesByAgency = agencyId => (dispatch, getState) => {
  const data = agencyService.getAdditionalTakeRatesByAgency(getState().user.authToken)(agencyId)
  dispatch({
    type: GET_ADDITIONAL_TAKE_RATES_BY_AGENCY.ACTION,
    payload: data,
  })
  return data
}
