import { defineAction } from '_utils/redux'
import * as emailService from '_services/email'

export const CREATE_EMAIL_CONFIGURATION = defineAction('CREATE_EMAIL_CONFIGURATION')
export const TEST_EMAIL_CONFIGURATION = defineAction('TEST_EMAIL_CONFIGURATION')
export const GET_EMAIL_CONFIGURATION = defineAction('GET_EMAIL_CONFIGURATION')

export const createEmail = (agencyId, payload) => async (dispatch, getState) => {
  const data = await emailService.createEmailService(getState().user.authToken)(agencyId, payload)

  dispatch({
    type: CREATE_EMAIL_CONFIGURATION.ACTION,
    payload: async () => data,
  })

  return data
}

export const editEmail = (agencyId, payload) => async (dispatch, getState) => {
  const data = await emailService.editEmailService(getState().user.authToken)(agencyId, payload)

  dispatch({
    type: CREATE_EMAIL_CONFIGURATION.ACTION,
    payload: async () => data,
  })

  return data
}

export const testEmail = payload => async (dispatch, getState) => {
  const data = await emailService.testEmailService(getState().user.authToken)(payload)

  dispatch({
    type: TEST_EMAIL_CONFIGURATION.ACTION,
    payload: async () => data,
  })

  return data
}

export const getEmail = agencyId => async (dispatch, getState) => {
  const data = await emailService.getEmailService(getState().user.authToken)(agencyId)

  dispatch({
    type: GET_EMAIL_CONFIGURATION.ACTION,
    payload: async () => data,
  })

  return data
}
