import { Record } from 'immutable'

import { createReducer } from '_utils/redux'

import { GET_EMAIL_CONFIGURATION, CREATE_EMAIL_CONFIGURATION } from './actions'

const INITIAL_STATE = new Record({
  currentEmail: undefined,
})()

export const email = createReducer(INITIAL_STATE, {
  [GET_EMAIL_CONFIGURATION.FULFILLED]: (state, { payload }) => {
    return state.update('currentEmail', () => payload)
  },

  [CREATE_EMAIL_CONFIGURATION.FULFILLED]: (state, { payload }) => {
    return state.update('currentEmail', () => payload)
  },
})
