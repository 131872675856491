import { Map } from 'immutable'

import { Budget } from '_models'

import {
  GET_BUDGET,
  UPDATE_BUDGET,
  DELETE_BUDGET_ITEM,
  UPDATE_BUDGET_ITEM,
  DELETE_BUDGET_PICTURE,
  ADD_BUDGET_PICTURE,
  APPROVE_BUDGET,
  GET_STEP_STATUS_LOG,
} from './actions'

export const getBudgetByIdSelector = budgetId => state =>
  state.budget.getIn(['results', `${budgetId}`])

export const getBudgetByIdSelectorJS = budgetId => state =>
  state.budget.getIn(['results', `${budgetId}`])?.toJS()

export const getBudgetLoadingSelector = state => state.loading.get(GET_BUDGET.ACTION)

export const getBudgetsSelector = state => state.budget.get('results')

export const getApprovedBudgetSelector = serviceOrderId => state =>
  state.budget
    .get('results')
    .filter(
      budget =>
        budget.serviceOrder === serviceOrderId &&
        (budget?.approved === true || budget?.counterProposal === true)
    )
    .first()

export const getApprovedBudgetFromServiceOrderSelector = serviceOrederId => state => {
  const approvedBudget = state.budget
    .get('results')
    .filter(
      budget =>
        budget.serviceOrder === serviceOrederId &&
        (budget?.approved === true || budget?.counterProposal === true)
    )
  return approvedBudget?.size > 0 ? approvedBudget : null
}

export const updateBudgetLoadingSelector = state => state.loading.get(UPDATE_BUDGET.ACTION)

export const approveBudgetLoadingSelector = state => state.loading.get(APPROVE_BUDGET.ACTION)

export const getStepStatusLogLoadingSelector = state =>
  state.loading.get(GET_STEP_STATUS_LOG.ACTION)

export const updateBudgetErrorSelector = state => state.error.get(UPDATE_BUDGET.ACTION, Map())

export const deleteBudgetItemErrorSelector = state =>
  state.error.get(DELETE_BUDGET_ITEM.ACTION, Map())

export const updateBudgetItemErrorSelector = state =>
  state.error.get(UPDATE_BUDGET_ITEM.ACTION, Map())

export const deleteBudgetPictureErrorSelector = state =>
  state.error.get(DELETE_BUDGET_PICTURE.ACTION, Map())

export const addBudgetPictureErrorSelector = state =>
  state.error.get(ADD_BUDGET_PICTURE.ACTION, Map())

export const getBudgetForProviderSelector = state => state.budget.get('providerBudget')

export const getNonOriginalBudgetListSelector = ({ budget }) => {
  const budgetMap = budget.get('results').toJS()
  return Object.keys(budgetMap)
    .map(targetBudget => budgetMap[targetBudget])
    .filter(targetBudget => targetBudget.id && !targetBudget.isOriginal)
}

export const getBudgetByServiceOrderSelector = state => state.budget.get('tradesmanBudget')

export const getPayerOptionsSelector = state => state.budget.get('payerOptions')

export const getOriginalBudget = budgetId => state => {
  const currentBudget = state.budget.getIn(['results', budgetId])

  if (!currentBudget?.get('proposeOpened')) {
    return state.budget.getIn(['results', `${budgetId}`])
  }

  const budgetList = state.budget.get('results').toJS()

  const isOriginalBudget = selectedBudget =>
    !selectedBudget.isOriginal &&
    !selectedBudget.refused &&
    selectedBudget.proposeOpened &&
    Number(selectedBudget.id) === Number(budgetId)

  const budget = Object.values(budgetList)?.find(isOriginalBudget)

  return new Budget(budget)
}

export const getStepStatusLogSelector = state => state.budget.get('stepStatusLog').toJS()

export const getCurrentInnerBudgetSelector = state => state.budget.get('currentInnerBudget')

export const getCoverageRuleSelector = state => state.budget.get('coverageRule')

export const getCoverageRuleVersionSelector = state => state.budget.get('coverageRuleVersion')

export const getReasonsToRefuseSelector =
  (budgetType = null) =>
  state => {
    const reasonsList = state.budget.get('reasonsToRefuse')?.toJS()
    const formattedList =
      budgetType === 'inner_budget'
        ? reasonsList.filter(item => item.innerBudget)
        : reasonsList.filter(item => item.externalBudget)

    return formattedList
  }
